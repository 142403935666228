import React from "react"
import {
  PageInformation,
  Tagman} from "trv-ebus-tcom-reactcomponents"
import BaseLayout from "../templates/baseLayout"
import "../styles/pages/error-page.scss"

const browser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  const tagman = <Tagman pageId="" appId="" tagManagerUrls={[]} />

  const pageInformation = (
    <PageInformation
      title="Page Not Found | Northfield"
      description=""
      keywords=""
      alternatePages={[]}
      sharingTool={{}}
    />
  )

  const headData = {
    pageInformation: pageInformation,
    tagman: tagman,
    htmlAttributes: { lang: "en-us" },
    canonicalUrl: ""
  }

  const disclaimer = [
      ]

  return (
    browser && (
      <BaseLayout head={headData} disclaimers={disclaimer}>
        <section role="main" className="error-page">
          <div className="ls-area article" id="content-body">
            <h1>Our apologies — we can't find the page you requested.</h1>
            <p>
              The page you're looking for may have been removed from the site,
              or the link you followed may not work anymore.
            </p>
            <p>
              If you entered the URL directly, you might have entered something
              incorrectly.
            </p>
          </div>
        </section>
      </BaseLayout>
    )
  )
}

export default NotFoundPage
